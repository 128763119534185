import React from 'react';
import { Layout } from '../components/common/layout/Layout';
import { SPINIcon } from '../components/svg/SPINIcon';

export default function Custom404() {
    return (
        <Layout>
            <div className="container error-container">
                <SPINIcon className="brand" />
                <h1 className="error-title">404 - Page not found</h1>
            </div>
        </Layout>
    );
}
